<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <!-- #1 -->
        <div v-if="id == 1">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">Le Beurre de Karité</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-karite.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Hydrate et nourrit en profondeur la peau, mais aussi les fibres capillaires...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Qu'est-ce que le beurre de karité ?</h1>
                                    <p class="text-mineralgreen text-base">
                                        Le beurre de karité est issu d'un arbre africain, le Butyrospermum parkii. C'est une amande naturellement 
                                        grasse présente dans les fruits et transformée qui donne ce produit, dont les nombreuses vertus cosmétiques 
                                        ne sont plus à prouver.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Ses bienfaits</h1>
                                    <p class="text-mineralgreen text-base">
                                        Le beurre de karité hydrate et nourrit en profondeur la peau, mais aussi les fibres capillaires. 
                                        Il présente d'ailleurs un gros avantage : il ne laisse pas de film gras lors de son application. 
                                        Protecteur contre les UV, le beurre de karité est notamment indispensable en été puisqu'il apaise 
                                        aussi les irritations cutanées, dues par exemple aux coups de soleil. Il permet encore de prolonger 
                                        le bronzage et d'aider la peau à garder toute son élasticité. Le beurre de karité démontre 
                                        ses bienfaits au quotidien, pour le soin des mains, des lèvres et des cheveux notamment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #2 -->
        <div v-if="id == 2">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">Le Miel</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-miel.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Absorbe et retient l'hydratation et laisse votre peau fraîche et souple...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Un soin pour une jolie peau</h1>
                                    <p class="text-mineralgreen text-base">
                                        Le miel peut faire de réels miracles sur votre peau (vous le trouvez d'ailleurs aujourd'hui 
                                        dans de nombreux produits de beauté). Ses bienfaits sont nombreux : anti-âge, hydratant, 
                                        antioxydant, le miel protège la peau des rayons UV et facilite sa régénération. De plus, 
                                        le miel absorbe et retient l'hydratation et laisse votre peau fraîche et souple
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Un soin pour vos cheveux</h1>
                                    <p class="text-mineralgreen text-base">
                                        Le miel est miraculeux pour les cheveux abîmés et secs et les tignasses
                                        qui ont perdu de leur éclat. Il assainit et fait briller.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Une solution contre l'acné</h1>
                                    <p class="text-mineralgreen text-base">
                                        L'acné est souvent causée par l'excès de sébum qui obstrue les pores de la peau. 
                                        Puisque le miel absorbe les petites impuretés des pores de la peau, il agit comme 
                                        un agent nettoyant. Ses vertus antiseptiques, adoucissantes et apaisantes font de 
                                        cet ingrédient magique le parfait remède contre les petits boutons.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #3 -->
        <div v-if="id == 3">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">L'Avocat</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-avocat.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Un fruit bon pour l’organisme et aussi excellent pour l’épiderme et la fibre capillaire...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Le pouvoir régénérateur de l’avocat</h1>
                                    <p class="text-mineralgreen text-base">
                                        Délicieux, l’avocat est un fruit bon pour l’organisme, mais celui-ci est aussi excellent pour 
                                        l’épiderme et la fibre capillaire. Composé de vitamines A, C et E, l’avocat est également riche en nutriments.
                                        <br><br>
                                        Ces propriétés exceptionnelles permettent de protéger la peau des agressions extérieures. 
                                        Mais surtout, il a un pouvoir régénérant. Il aide à la cicatrisation et il lutte contre le 
                                        vieillissement de l’épiderme
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Les bienfaits de l’huile d’avocat</h1>
                                    <p class="text-mineralgreen text-base">
                                        Depuis des milliers d’années, l’avocat est utilisé pour soigner les blessures et les gerçures de l’épiderme. 
                                        L’huile végétale d’avocat convient à tous les types de peaux et s’intègre très facilement dans une routine beauté. Son huile hydrate les peaux sèches et fragiles. Avec ses propriétés anti-âge, l’huile d’avocat estompe les poches et les cernes.
                                        <br><br>
                                        Sur les cheveux, l’huile d’avocat fait aussi des miracles ! Elle redonne du tonus aux cheveux ternes, 
                                        et nourrit les chevelures abîmées.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #4 -->
        <div v-if="id == 4">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">L'Aloe Vera</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-aloe-vera.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Actif apaisant idéal pour la peau. Il est un puissant cicatrisant...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Le gel d'Aloe Vera est un puissant cicatrisant</h1>
                                    <p class="text-mineralgreen text-base">
                                        Le gel contenu dans les feuilles de la plante d'aloe vera contient des glycoprotéines, 
                                        qui stimulent considérablement la capacité de guérison de la peau en apaisant la douleur 
                                        et l'inflammation. Les autres substances cicatrisantes du gel d'aloe vera sont des 
                                        polysaccharides, qui dynamisent la régénération et la réparation cutanées.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Le gel d'Aloe Vera hydrate en profondeur</h1>
                                    <p class="text-mineralgreen text-base">
                                        D'après certaines études, les vertus hydratantes et apaisantes du gel d'aloe vera 
                                        le rendent idéal pour éliminer les pellicules et le psoriasis, ainsi que d'autres 
                                        formes de peau sèche ou gercée. Ce gel s'applique d'une couche sur le cuir chevelu 
                                        ou sur le visage, en guise de masque hydratant et relaxant.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Le gel d'Aloe Vera combat les infections</h1>
                                    <p class="text-mineralgreen text-base">
                                        Le gel d'aloe vera contient un agent antibactérien naturel, c'est pourquoi on l'utilise 
                                        dans les crèmes ou gels à raser. En effet, il prévient les poils incarnés. Utilisé seul 
                                        ou combiné à un agent hydratant, c'est également un démaquillant efficace, surtout pour 
                                        les personnes à la peau sensible.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #5 -->
        <div v-if="id == 5">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">Le Curcuma</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-curcuma.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Il a plusieurs application : anti-inflammatoire, anti-âge et booster de collagène...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Anti-inflammatoire</h1>
                                    <p class="text-mineralgreen text-base">
                                        Sur la peau, les inflammations peuvent avoir plusieurs formes comme l’acné, l’eczéma 
                                        et le psoriasis. Hautement anti-inflammatoire, le curcuma est un allié idéal pour apaiser 
                                        ces peaux sensibles. Il lutte efficacement contre l’acné en régulant la production 
                                        de sébum, Il illumine la peau et donne de l’éclat au visage
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Anti-âge</h1>
                                    <p class="text-mineralgreen text-base">
                                        Les radicaux libres, la pollutions, les UV et le stress sont des facteurs aggravants du 
                                        vieillissement cutané. Fort en anti-oxydants, le curcuma aide à lutter contre ce phénomène.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Booste le collagène</h1>
                                    <p class="text-mineralgreen text-base">
                                        Le collagène on en parle souvent lorsqu’il s’agit de traiter le vieillissement cutané. 
                                        Déjà présent dans la peau, sa production baisse avec le temps et la peau devient moins 
                                        élastique. Le curcuma permet de relancer sa production et de booster son capital jeunesse.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #6 -->
        <div v-if="id == 6">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">Le Concombre</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-concombre.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Le concombre a des propriétés hydratantes, cicatrisantes, assainissantes et astringentes...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Ses apports en vitamines</h1>
                                    <p class="text-mineralgreen text-base">
                                        Il procure un large éventail de minéraux et vitamines (plus concentrés dans sa peau) : 
                                        du potassium, toutes les vitamines du groupe B, de la vitamine C (8 mg aux 100 g), et 
                                        un peu de provitamine A et de vitamine E.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Des vertus uniques</h1>
                                    <p class="text-mineralgreen text-base">
                                        On attribue au jus de concombre absorbé à jeun des propriétés dépuratives. Sa richesse 
                                        en eau (96%) lui confère des vertus diurétiques et drainantes. Bien pourvu en fibres, 
                                        il participe au bon fonctionnement du transit intestinal. Le concombre serait également 
                                        un calmant naturel (anti-stress et anxiété).
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Côté beauté</h1>
                                    <p class="text-mineralgreen text-base">
                                        Le concombre a des propriétés hydratantes, cicatrisantes, assainissantes et astringentes. 
                                        Il est considéré comme un adoucissant, et on l’utilise traditionnellement (sous forme de 
                                        cataplasme ou de lotion) pour lutter contre les rougeurs, les dartres, les taches de rousseurs, 
                                        les démangeaisons, les cernes et même les rides ! Il est également parfait pour redonner de 
                                        l'éclat à la peau et la raffermir, purifier le teint et resserrer les pores.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #7 -->
        <div v-if="id == 7">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">La Noix de Coco</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-coco.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Connue pour être un excellent soin cheveux et aussi riche en acides gras...</h1>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Les cheveux absorbent l’huile de coco mieux que tout autre</h1>
                                    <p class="text-mineralgreen text-base">
                                        L'huile de coco est connue pour être un excellent soin cheveux pour une bonne raison : 
                                        c'est l'une des huiles naturelles qui pénètrent le mieux. Une récente étude a établi 
                                        qu'en l'espace d'une heure, nos cheveux pouvaient absorber jusqu'à l'équivalent de 15 % 
                                        de leur masse en huile de coco, et jusqu'à 20 à 25 % en six heures. L'huile de coco pure 
                                        est néanmoins efficace même à petite dose.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">La puissance de l’huile de Coco</h1>
                                    <p class="text-mineralgreen text-base">
                                        Il faut noter que par elle-même, l'huile de coco est riche en acides gras, mais pauvre 
                                        en vitamines. Associée à d'autres actifs bénéfiques tels que des vitamines et nutriments 
                                        dans un produit de beauté, l'huile de coco pénètre plus profondément dans le cuir chevelu 
                                        et les fibres capillaires, ce qui en fait un excellent vecteur d'autres substances. 
                                        L'huile de coco pure aide aussi à maintenir des agents hydratants aqueux complémentaires 
                                        plus en profondeur dans la peau et les cheveux.
                                        <br><br>
                                        Grâce à ses propriétés antibactériennes naturelles, l'huile de coco crue peut prévenir 
                                        l'apparition de pellicules et d'infections fongiques, de la teigne, et d'autres infections 
                                        et irritations du cuir chevelu.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #8 -->
        <div v-if="id == 8">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">Le Beurre de Cacao</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-beurre-de-cacao.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Il est hydratant, antioxydant et cicatrisant. Idéal pour les cheveux...</h1>
                                    <p class="text-mineralgreen text-base">
                                        Certes moins connu que le beurre de karité, le beurre de cacao n'en est pas moins 
                                        pourvu de nombreuses vertus. Il est hydratant, antioxydant, cicatrisant... La liste 
                                        de ses propriétés est extraordinairement longue et mérite que l'on s'y attarde.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Hydratant</h1>
                                    <p class="text-mineralgreen text-base">
                                        Idéal pour les peaux sèches, abîmées ou encore déshydratées, le beurre de cacao est 
                                        riche en acides stéarique, palmitique et oléique ce qui lui profère une action hydratante 
                                        intense. Utilisé sur les peaux dévitalisées, le beurre de cacao apaise et nourrit. 
                                        Il peut soulager certaines irritations telles que l'eczéma.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Antioxydant et Cicatrisant</h1>
                                    <p class="text-mineralgreen text-base">
                                        Le beurre de cacao est particulièrement nourrissant parce qu'il est riche en matières grasses, 
                                        mais aussi en flavonoïdes, d'où son action anti-oxydante qui lui confère des bienfaits apaisants 
                                        et cicatrisants. Il est conseillé pour les femmes enceintes car participe à la prévention 
                                        des vergetures ainsi qu'à la formation de cellulite.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Idéal pour les cheveux</h1>
                                    <p class="text-mineralgreen text-base">
                                        Grâce à son action hydratante intense, le beurre de cacao est également utilisé en soin 
                                        pour cheveux abîmés et secs. Il nourrit le cheveu tout en lui rendant sa brillance.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #9 -->
        <div v-if="id == 9">
            <div class="bg-cover bg-nature-drop-water">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">Huile de Sésame</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                     <q-img fit="cover" class="img-smooth-zoom" ratio="1" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-huile-de-sesame.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">L’huile de sésame est riche en vitamine E et en vitamine K1...</h1>
                                    <p class="text-mineralgreen text-base">
                                        L’huile de sésame regorge également d'acides gras mono insaturés 
                                        et d'acides gras polyinsaturés. Ses nutriments bénéfiques lorsqu’elle 
                                        est utilisée en cuisine, mais l’huile de sésame fait aussi 
                                        des miracles en cosmétique et dans le cadre de massages
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Les bienfaits HUILE DE SESAME pour les cheveux</h1>
                                    <p class="text-mineralgreen text-base">
                                        Pour faire briller votre chevelure sans utiliser de produits chimiques, 
                                        optez pour l’huile de sésame. Ses nutriments essentiels vont protéger 
                                        la fibre capillaire et le cuir chevelu, limitant ainsi l’apparition 
                                        de pellicules et la perte de cheveux. Chez les enfants, 
                                        c’est une arme anti-poux redoutable.

                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">Les bienfaits HUILE DE SESAME pour la peau</h1>
                                    <p class="text-mineralgreen text-base">
                                        Riche en acides gras essentiels, l'huile de sésame régénère et assouplit la peau. 
                                        La vitamine E, la lécithine et la sésamoline sont autant d'antioxydants naturels 
                                        qui entrent dans la composition de l'huile de sésame et vont donc protéger 
                                        la peau du vieillissement cutané, particulièrement en cas d'exposition au soleil. 
                                        Régénérant, assouplissante et hydratante, l'huile de sésame convient tout particulièrement 
                                        aux peaux irritées ou desséchées. Elle s'applique seule ou mélangée avec une autre huile 
                                        végétale. On peut aussi l'utiliser en soin anti-âge sur le visage.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <!-- ABOUT -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="container mx-auto">
                        <div class="row">

                            <div class="col-12">
                                <h1 class="text-mineralgreen text-center text-2xl font-black font-epigrafica mt-2 uppercase">Découvrez d'autres ingrédients</h1>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">L'Aloe Vera</h1>
                                            <p class="text-mineralgreen mb-2">Actif apaisant idéal pour la peau. Il est un puissant cicatrisant...</p>
                                            <a href="/page/ingredient/4/aloe-vera" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-aloe-vera.jpg" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">L'Avocat</h1>
                                            <p class="text-mineralgreen mb-2">Un fruit bon pour l’organisme et aussi excellent pour l’épiderme et la fibre capillaire...</p>
                                            <a href="/page/ingredient/3/avocat" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-avocat.jpg" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="relative">
                                    <div class="relative mb-20">
                                        <div class="absolute z-40 bg-white m-10 p-4 -bottom-24 border-solid border-pastelgolden border-b-2">
                                            <h1 class="text-mineralgreen text-lg font-black font-epigrafica uppercase">Le Concombre</h1>
                                            <p class="text-mineralgreen mb-2">Le concombre a des propriétés hydratantes, cicatrisantes, assainissantes et astringentes...</p>
                                            <a href="/page/ingredient/6/concombre" class="text-mineralgreen-dark hover:text-mineralgreen font-bold">Découvrir &rarr;</a>
                                        </div>
                                        <q-img width="100%" src="@/assets/media/ingredients/ibc-international-bio-cosmetics-concombre.jpg" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/Navbar"
import footerbar from "@/views/shared/Footer"
import { useMeta } from "quasar"

export default {
    name: "Ingredient",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    computed: {
        id () { return this.$route.params.id },
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Ingrédient', // sets document title
        })
    }
};
</script>